import React from 'react';
import { slugify } from '../../../utils/helpers'

export const File = (props) => {
  const { field, value, handleChange } = props
  const {type, name, placeholder, required, className, accept = [] } = field
  return (
    <div className={`file-container ${className}${value[slugify(name)] ? ' active' : ''}`}>
      <div className="half-col message">
        <span>Attach your resume and cover letter</span>
      </div><div className="half-col relative">
        <div className="hide-overflow">
          {/* <label htmlFor={slugify(name)}>Browse...</label> */}
          <input id={slugify(name)} name={slugify(name)} accept={accept.join()} type="file" required={required ? true : false} onChange={handleChange} value={value[slugify(name)]} />
        </div>
      </div>
    </div>
  )
}
