import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Select = (props) => {
  const { field, value, handleChange } = props
  const {type, name, placeholder, required, className, options = {} } = field
  return (
    <div className={`${className}${value[slugify(name)] ? ' active' : ''}`}>
      <label htmlFor={slugify(name)}>{placeholder}</label>
      <select id={slugify(name)} name={name} required={required ? true : false} defaultValue="" onChange={handleChange} value={value[slugify(name)]}>
      {placeholder ? <option disabled value="">{placeholder}</option> : null}
      {options && options.map((option, index) => (
        <option key={index} value={slugify(option.value)}>{option.label}</option>
      ))}
      </select>
    </div>
  )
}
