import React from 'react';
import OnVisible, { setDefaultProps } from 'react-on-visible';
import { Link } from 'gatsby';
import './ContactUsBox.scss';
import Image from '../../components/Image'

export const ContactUsBox = props => {
  const { 
    subtitle,
    heading,
    content,
    buttonLink,
    buttonText,
    image
  } = props
  return (
    <section className={`contact-us-box TextBlock on-light`}>
      <div className="background-helper"></div>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="flex-row">
            <OnVisible className="content text-box tb2">
              <div className="content-wrapper">
                { subtitle && <span className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} /> }
                { heading && <h2 className="title" dangerouslySetInnerHTML={{__html: heading}} /> }
                <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: content}} />
                {buttonLink && (
                      <Link
                        to={buttonLink}
                        activeClassName="active"
                        className={`button on-light`}
                        aria-current="true"
                      >
                        <span>{ buttonText ? buttonText : 'Learn More' }</span>
                      </Link>
                  )}
                </div>
                <div className="image-wrapper">
                  {image && <Image image={image} />}
                </div>
              </OnVisible>
          </div>
        </div>
      </div>
    </section>
  )
}
