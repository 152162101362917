export const getPageSlugFromWpLink = (wpLink, wordPressUrl) => {
  if (!wpLink) return null;
  return wpLink.replace(wordPressUrl, '');
}

export const scrollTo = (elementY, duration) => {
  if (typeof window !== 'undefined') {
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);
      window.scrollTo({
        top: startingY + diff * percent,
        left: 0,
        behaviour: 'smooth'
      });

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    })
  }
}

export const slugify = (string) => {
  return string.trim().toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,'').replace(' ', '-')
}

export const isClient = typeof window !== 'undefined';

export const getUrlVars = () => {
    var vars = [], hash;
    var query_string = isClient && window.location.search;

    if (query_string) {
        var hashes = query_string.slice(1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars[hash[0]] = hash[1];
        }

        return vars;
    } else {
        return false;
    }
}

export const compileQuery = (newKey, newValue = 'null', remove = null) => {
  if (isClient) {
    var params = getUrlVars()
  }
  var queryString = []
  if (params) {
    //Add new
    !params[newKey] && newValue != 'null' && queryString.push(`${newKey}=${newValue}`)
    //Update existing
    Object.keys(params).forEach(key => {
      var thisValue = newKey === key ? newValue : params[key]
      thisValue != 'null' && remove != key && queryString.push(`${key}=${thisValue}`)
    })
  } else {
    //Add new
    newValue != 'null' && queryString.push(`${newKey}=${newValue}`)
  }

  return queryString.length > 0 ? '?' + queryString.join('&') : ''
}
