import React from 'react';
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './Hero.scss';
import Image from '../components/Image';
import PageGrid from '../components/PageGrid/PageGrid';

class HeroClass extends React.Component {
  constructor(props) {
    super(props);
    // let isIE = false;
    this.state = {
      isIE: false,
      isAboutPage: false,
    };
  }
  componentDidMount() {
    this.setState({
      isIE: '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style
    })

    if(this.props.location !== undefined) {
      if ((this.props.location.pathname).indexOf('about-us') !== -1) {
        this.setState({isAboutPage: true});
      }
    }
  }
  render() {
    const {
      subheading,
      heading,
      blurb,
      backgroundImage,
      isCaseStudy,
    } = this.props;
    const { isAboutPage } = this.state;
    return (
      <>
      <section 
        className=
          {`Hero${this.state.isIE ? ' isIE' : ''}`} 
        id={isAboutPage ? 'about-page' : null}>
        <div className={`background-wrapper${isAboutPage ? ' bg-overflow' : ''}`}>
          <div className={`background-image__wrapper`}>
            <div className="grid">
              <PageGrid />
            </div>
            { backgroundImage && (
              <Image className="background-image" image={backgroundImage}>
              </Image>
            ) }
          </div>
        </div>
        <div className="content-container">
          <div className="content-position-helper wrapper">
            {/* <div className="right-blocks">
              <div className="block-wrapper">
                <div className="block"></div>
              </div>
              <div className="block-wrapper">
                <div className="block"></div>
              </div>
            </div> */}
            <div className="content">
              <div className="subtitle-wrap">
                {subheading && (
                  <span className="subtitle" dangerouslySetInnerHTML={{__html: subheading}} />
                )}
              </div>
              {heading && (
                <div className="h1-wrap">
                  <h1 className="heading" dangerouslySetInnerHTML={{__html: heading}} />
                </div>
              )}
              {blurb && (
                <p className="blurb" dangerouslySetInnerHTML={{__html: blurb}} />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <span id="read-more" className="anchor-link"></span> */}
      </>
    )
  }
}

export const Hero = (props) => ( <HeroClass {...props} /> );
