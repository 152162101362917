import React from 'react';
import './HomeTextContentArea.scss';
import Image from '../../components/Image';
import ArrowDown from '../../components/ArrowDown/ArrowDown';

export const HomeTextContentArea = props => {
  const { contentCopy, button, buttonText, toggleOptions, backgroundImage } = props.Content[0];
  return (
    <section className={'home-text-content'} id={'home_text_content_area'}>
      <div className="background-image__wrapper">
      { backgroundImage && (
        <Image className="background-image" image={backgroundImage} />
      ) }
      </div>
      <div className={props.className ? props.className : 'content-copy-wrapper'}>
        <div className={'content-copy'}>
          <h2 dangerouslySetInnerHTML={{__html: contentCopy}} />
        </div>
        <a className='button' href={button}>{buttonText}</a>
        {toggleOptions[0].toggleArrowDown && <ArrowDown autoMode={true}/>}
        {/* {toggleOptions[0].toggleShowBlocks && null} */}
      </div>
    </section>
  )
}