import React, { Component } from 'react';
import './PageGrid.scss';

class PageGrid extends React.Component {
  constructor(props) {
    super(props);
    // Configure some defau
    this.state = {
      windowDimensions: {
        width: 1400,
        height: 800,
      },
      cellCount: 8,
      isLoaded: false,
    };
  }
  
  componentDidMount() {
    const windowDimensions = {
      width: window.innerWidth || document.documentElement.offsetWidth,
      height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    }
    // this.setState({
    //   windowDimensions: {
    //     width: window.innerWidth || document.documentElement.offsetWidth,
    //     height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    //   }
    // });
    this.setState({windowDimensions})
    this.setState({isLoaded: true})
    if ((windowDimensions.width < 600) || (windowDimensions.height < 600)) {
      return null;
    } 
    if (windowDimensions.width > 1600) {
      this.setState({cellCount: 10});
    }
  }
  /*
   * A rectangle of A x B dimensions can have AB 1x1 squares.
   */ 
  createGrid = () => {
    const gridRow = [];
    const windowDimensions = this.state.windowDimensions;
    // An even amount of squres is ideal across the horizontal, hence dividing the viewport width by desired cell count.
    const cellSize = windowDimensions.width / this.state.cellCount;
    // We need to determine the ratio of cells for width and height
    const ratioW = Math.ceil(windowDimensions.width / cellSize);
    const ratioH = Math.ceil(windowDimensions.height / cellSize);
    const cellHeightAndWidth = cellSize + 'px';
    for (let i = 0; i < ratioH; i++) {
      const cells = [];
      for (let p = 0; p < ratioW; p++) {
        cells.push(
          <div key={p} className={`grid-cell gc-${p}`} style={{width: cellHeightAndWidth, height: cellHeightAndWidth}}>
          </div>
        )
      }
      gridRow.push(<div key={i} className={`grid-row gr-${i}`}>{cells}</div>);
    }
    return gridRow;
  }
  
  render() {
    const isLoaded = this.state.isLoaded;
    const windowDimensions = this.state.windowDimensions;
    const cellSize = windowDimensions.width / this.state.cellCount;
    const ratioW = Math.ceil(windowDimensions.width / cellSize);
    const ratioH = Math.ceil(windowDimensions.height / cellSize);
    const gridSizeW = ratioW * cellSize + 'px';
    const gridSizeH = ratioH * cellSize + 'px';
    return (
      <>
        {isLoaded && 
        <GridWrapper style={{width: gridSizeW, height: gridSizeH}}>
          {this.createGrid()}
        </GridWrapper>}
      </>
    )
  } 
}
const GridWrapper = (props) => {
  return <div className={props.className ? props.className : 'grid-wrapper'}>{props.children}</div>
}

export default PageGrid;