import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import './FeaturedProjectsSliderFeed.scss'
import Image from '../../components/Image'
import GatsbyLink from '../../components/GatsbyLink'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import OnVisible from 'react-on-visible'

class ProjectsFeedOutput extends React.Component {
  render() {
    const shuffle = a => {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[a[i], a[j]] = [a[j], a[i]]
      }
      return a
    }
    const {
      optionalText,
      manuallySelectCaseStudies,
      caseStudiesList,
      customFilterLink,
      customButtonText,
    } = this.props

    const { featuredCaseStudyItem, featuredCaseStudyItemInfo } = caseStudiesList
    const { caseStudies, location } = this.props.data
    
    let dragging = false

    var sliderSettings = {
      dots: false,
      arrows: true,
      // infinite: true,
      rewind: true,
      speed: 1000,
      className: 'center',
      useTransform: false,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      // beforeChange: () => (dragging = true),
      // afterChange: () => (dragging = false),
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    }

    // hack because ACF post object doesn't work with graphql
    const shuffleCaseStudies = shuffle(caseStudies.edges)
    const filteredCaseStudies = shuffleCaseStudies.filter(cs =>
      caseStudiesList
        .map(
          csl => parseInt(csl.featuredCaseStudyItem.guid.match(/\d+$/)[0]),
          10
        )
        .includes(cs.node.wordpress_id)
    )
    
    if (shuffleCaseStudies) {
      return (
        <section className="FeaturedProjectsFeed">
          {/* <div className="background-helper"></div> */}
          <OnVisible percent={50}>
          <div className="wrapper">
            <div className="content-wrapper">
              {optionalText && (
                <div
                  className="wysiwygcontent"
                  dangerouslySetInnerHTML={{ __html: optionalText }}
                />
              )}
            </div>
            <div className="content-overlay">
              <div className="flex-row">
                <Slider {...sliderSettings}>
                  {manuallySelectCaseStudies && filteredCaseStudies
                    ? filteredCaseStudies.slice(0, 3).map((cs, index) => (
                        <div className="flex-item" key={index}>
                          <GatsbyLink
                            className="image-text__wrapper"
                            to={`/case-studies/${cs.node.slug}/`}
                            decode={false}
                            onClick={e => dragging && e.preventDefault()}
                          >
                            <div className="image-container">
                              <Image
                                className="featured-image"
                                image={
                                  cs.node.featuredImage
                                    ? cs.node.featuredImage
                                    : ''
                                }
                              />
                            </div>
                            <div className="text-container">
                              <div className="text-inner-wrapper">
                                <span
                                  className="title"
                                  dangerouslySetInnerHTML={{
                                    __html: cs.node.title,
                                  }}
                                />
                                {cs.node.location[0] ? (
                                  <span className="location">
                                    {cs.node.location[0]
                                      ? location.edges.find(
                                          l =>
                                            l.node.wordpress_id ==
                                            cs.node.location[0]
                                        ).node.name
                                      : ''}
                                  </span>
                                ) : (
                                  ''
                                )}
                                <span className="line">
                                  Africa Mali
                                </span>
                                <span className="line line__last">
                                  Company Name
                                </span>
                                <p className="blurb">
                                  Lycopodium’s involvement in the development of this project is a continuation of a successful association with two other B2Gold projects – Otjikoto in Namibia and Masbate in the Philippines.
                                </p>
                              </div>
                              <div className="view-all-button">
                                <Link
                                  to={
                                    customFilterLink
                                      ? `/case-studies/filter?${customFilterLink}`
                                      : `/case-studies/`
                                  }
                                  activeClassName="active"
                                  className="button"
                                  aria-current="true"
                                >
                                  <span>
                                    {customButtonText
                                      ? customButtonText
                                      : 'View All Projects'}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </GatsbyLink>
                        </div>
                      ))
                    : shuffleCaseStudies &&
                      shuffleCaseStudies.slice(0, 3).map((cs, index) => (
                        <div className="flex-item" key={index}>
                          <GatsbyLink
                            to={`/case-studies/${cs.node.slug}/`}
                            decode={false}
                            onClick={e => dragging && e.preventDefault()}
                          >
                            <div className="image-container">
                              <Image
                                className="featured-image"
                                image={
                                  cs.node.featuredImage
                                    ? cs.node.featuredImage
                                    : ''
                                }
                              />
                            </div>
                            <div className="text-container">
                              <span
                                className="title"
                                dangerouslySetInnerHTML={{
                                  __html: cs.node.title,
                                }}
                              />
                              {cs.node.location[0] ? (
                                <span className="location">
                                  {cs.node.location[0]
                                    ? location.edges.find(
                                        l =>
                                          l.node.wordpress_id ==
                                          cs.node.location[0]
                                      ).node.name
                                    : ''}
                                </span>
                              ) : (
                                ''
                              )}
                              <div className="read-more">
                                <span className="text">Read More</span>
                                <div className="overflow-hidden">
                                  <div className="arrow"></div>
                                </div>
                              </div>
                            </div>
                          </GatsbyLink>
                        </div>
                      ))}
                </Slider>
              </div>
            </div>
          </div>
          </OnVisible>
        </section>
      )
    }

    return (
      <section className="ProjectsFeed">
        <div className="background-helper"></div>
        <div className="wrapper">
          <span>No case studies found</span>
        </div>
      </section>
    )
  }
}

export const FeaturedProjectsSliderFeed = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          caseStudies: allWordpressWpCaseStudies(
            sort: { fields: date, order: DESC }
          ) # limit: 3
          {
            edges {
              node {
                slug
                title
                location
                sector
                wordpress_id
                featuredImage: featured_media {
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 450
                        quality: 90
                        srcSetBreakpoints: [450]
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
          location: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
          sector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
        }
      `}
      render={data => <ProjectsFeedOutput {...props} data={data} />}
    />
  )
}
