import React from 'react';
import './ContactForm.scss';
import Image from '../components/Image';
import Leads from '../components/Leads/index'

export const ContactForm = props => {
  const { 
    theme,
    formType,
    subheading,
    heading,
    content
  } = props
  const settingsLeads = [
    {
      type: 'text',
      name: 'firstname',
      placeholder: 'First Name*',
      required: true,
      className: 'input-container half-col'
    },
    {
      type: 'text',
      name: 'lastname',
      placeholder: 'Last Name*',
      required: true,
      className: 'input-container half-col'
    },
    {
      type: 'phone',
      name: 'phone',
      placeholder: 'Phone Number*',
      required: true,
      className: 'input-container half-col'
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Email Address*',
      required: true,
      className: 'input-container half-col'
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: 'Tell us a bit about yourself (100 words)*',
      required: true,
      className: 'input-container'
    },
    {
      type: 'file',
      name: 'resume',
      placeholder: 'Your resume',
      required: false,
      className: ''
    }
  ]
  const settingsGeneral = [
    {
      type: 'text',
      name: 'firstname',
      placeholder: 'First Name*',
      required: true,
      className: 'input-container half-col'
    },
    {
      type: 'text',
      name: 'lastname',
      placeholder: 'Last Name*',
      required: true,
      className: 'input-container half-col'
    },
    {
      type: 'phone',
      name: 'phone',
      placeholder: 'Phone Number*',
      required: true,
      className: 'input-container half-col'
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Email Address*',
      required: true,
      className: 'input-container half-col'
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: 'Your Enquiry*',
      required: true,
      className: 'input-container'
    }
  ]

  // const { wordpressUrl } = site.siteMetadata;

  return (
    <section className={`ContactForm ${theme}`}>
      <div className="background-helper"></div>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="form-container">
            <span className="subtitle" dangerouslySetInnerHTML={{__html: subheading}} />
            <div className="wysiwygcontent">
              <h2 className="heading" dangerouslySetInnerHTML={{__html: heading}} />
              <div className="" dangerouslySetInnerHTML={{__html: content}} />
            </div>
            <span className="required-fields">* Indicates required field</span>
            { formType == 'general' ? (
              <Leads settings={settingsGeneral}/>
            ) : ''}
            { formType == 'job-application' ? (
              <Leads settings={settingsGeneral}/>
            ) : '' }
          </div>
        </div>
      </div>
    </section>
  )
}
