import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import { StaticQuery, graphql, navigate } from 'gatsby';
import ReCAPTCHA from "react-google-recaptcha";
// import { objectToQuerystring, slugify } from '../../utils/helpers'
const recaptchaRef = React.createRef();
import * as FormFields from './Fields';
import { FaCircleNotch, FaExclamation } from 'react-icons/fa';

class Index extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        loading: false,
        submitted: false,
        lead: {
          location: typeof window !== `undefined` ? window.location.href : 'Server',
        },
      }
    }

    handleSubmit = ( e ) => {
      e.preventDefault()
      const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue() ;
      const formReValue = e.target.elements['g-recaptcha-response'].value;

      if ( formReValue !== '' && recaptchaValue === formReValue ) {
        this.captchaSubmit( e )
      }
    }

    captchaSubmit = async ( e ) => {
      this.setState({ loading: true });
      const theFunctionsUrl = `/.netlify/functions/leads` //${objectToQuerystring(this.state.lead)}
      //axios.post()
      fetch(theFunctionsUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({lead: this.state.lead})
      })
      .then(response => {
       console.log('response:',response)
       this.setState({ loading: false, submitted: true, lead: {} });
      })
      .catch(error => {
        console.error('submitForm Error', error)
        this.setState({ loading: false, error: true, lead: {} });
      });
    }

    //Form change
    handleChange(e) {
       let lead = this.state.lead
       //If file
       if (e.target.type === 'file') {

         let fileName = `lead-file-${e.target.name}`
         let file = e.target.files[0];
         lead[fileName] = { data: '', file: '' }

         if (file) {
            const reader = new FileReader(file);
            reader.readAsDataURL(file);
            reader.onload = () => {
              // set image and base64'd image data in component state
              lead[fileName].data = reader.result
              lead[fileName].file = file.name
            }
          }
       } else if ( e.target.type === 'checkbox' ) {
         console.log('Checkbox: ', lead[e.target.name])
         //Toggle value on off
         lead[e.target.name] === e.target.value ? lead[e.target.name] = '' : lead[e.target.name] = e.target.value
       } else {
         //All other fields
         lead[e.target.name] = e.target.value
       }
       this.setState({lead})
    }

    render() {
      const { loading, submitted, error, lead } = this.state
      const { settings, thankYou = null, data } = this.props
      const { siteSettings } = data
      if ( submitted ) return thankYou ? navigate(thankYou) : <div className="lead-form thank-you"><h3>Thank you!</h3><p>Your message has been sent.</p></div>
      const formatComponentName = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
      return (
        <div className="lead-form">
          {error && <div className="error"><div>There was a error <FaExclamation /></div></div>}
          {loading && <div className="loading"><div><FaCircleNotch className="spin" /> Loading...</div></div>}
          <form onSubmit={event => {this.handleSubmit(event)}}>
              {settings.map((field, index) => {
                const FormComponent = FormFields[formatComponentName(field.type)];
                return (
                  <FormComponent key={index} field={field} value={lead} handleChange={e => this.handleChange(e)} />
                )
              })}
              <div className="captcha-wrapper">
                <ReCAPTCHA
                  className="captcha"
                  ref={recaptchaRef}
                  sitekey={siteSettings.options.googleSiteKey}
                />
              </div>
              <div className="button-wrapper">
                <button className="submit button on-dark">Submit</button>
              </div>
          </form>
        </div>
      )
    }

}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            googleSiteKey
          }
        },
      }
    `}
    render={data => <Index data={data} {...props} />}
  />
)
