import React from 'react'
import OnVisible, { setDefaultProps } from 'react-on-visible'
import { Link } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'
import RenderContent from '../../components/RenderContent'
import Image from '../../components/Image';
import './HomeThreeImage.scss'

export const HomeThreeImage = props => {
  const { images, backgroundImage } = props;
  const { imageLeft, imageRightTop, imageRightBot } = images[0];

  // Example of poor naming
  const { textContent, heading, subtitle } = props.textContent;
  return (
    <section className="home-three-image">
      <div className="background-image__wrapper">
      { backgroundImage && (
        <Image className="background-image" image={backgroundImage} />
      ) }
      </div>
      <div className="section-wrapper">
        {/* <OnVisible percent={10}> */}
          <div className="flex-row">
            <div className="flex-col column-one">
              <div className="content">
                <span className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
                <h2 className="title" dangerouslySetInnerHTML={{__html: heading}} />
                <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: textContent}} />
              </div>
              <div className="image-wrapper">
              <ParallaxProvider>
                <OnVisible percent={50} className="content image-box">
                <Parallax
                    className="custom-class"
                    y={[-14, 14]}
                    tagOuter="figure">
                  {imageLeft && <Image image={imageLeft} />}     
                  <div className="image-mask"></div>       
                </Parallax>
                </OnVisible>
              </ParallaxProvider>
              </div>
            </div>
            <div className="flex-col">
              
              <div className="image-wrapper">
              <ParallaxProvider>
              <OnVisible percent={50} className="content image-box">
              <Parallax
                    className="custom-class"
                    y={[-14, 14]}
                    tagOuter="figure">
                {imageRightTop && <Image image={imageRightTop} />}
                <div className="image-mask"></div>
                </Parallax>
                </OnVisible>
                <div className="image-accent">
                  <div className="block-one">
                  </div>
                  <div className="block-two">
                  </div>
                </div>
              </ParallaxProvider>
              </div>
              <div className="image-wrapper image-wrapper__bottom">
                <ParallaxProvider>
                  <OnVisible percent={50} className="content image-box">
                  <Parallax
                    className="custom-class"
                    y={[-14, 14]}
                    tagOuter="figure">
                  {imageRightBot && <Image image={imageRightBot} />}     
                  <div className="image-mask"></div>       
                  </Parallax>
                </OnVisible>
                </ParallaxProvider>
              </div>
            </div>
          </div>
  
      </div>
    </section>  
  )
}