import React, { Component } from "react"
import { StaticQuery, graphql } from 'gatsby';
import GoogleMapReact from 'google-map-react';
import './Projects.scss'
// import CountUp from 'react-countup';
import { slugify, isClient, compileQuery } from '../utils/helpers'
import GatsbyLink from '../components/GatsbyLink'
import CustomCountUp from '../components/CustomCountUp'
import 'url-search-params-polyfill';
import { FaTimes } from 'react-icons/fa';

const MapLocation = (props) => {
  const { location, toggleMapLocation, active, index } = props
  const isOpen = active === index;
  return (
      <div className={`marker${isOpen ? ' active' : ''}`}>
        <span onClick={event => toggleMapLocation(event, index, location.lat, location.lng )}/>
        <div className="projects">
          <span className="close-button" onClick={event => toggleMapLocation(event, index, location.lat, location.lng )}><FaTimes /></span>
          <h3>{location.location}</h3>
          <ul>
            {location.projects && location.projects.map((project, index) =>
              <li key={index}>{project.title}</li>
            )}
          </ul>
          <GatsbyLink to={`/case-studies/filter${compileQuery('location',slugify(location.location),'region')}`} decode={false} className="link">
          <div className="read-more">
            <span className="text">View Case Studies</span>
            <div className="overflow-hidden">
              <div className="arrow"></div>
            </div>
          </div>
          </GatsbyLink>
          {/* <GatsbyLink to={`/case-studies/filter${compileQuery('location',slugify(location.location),'region')}`} className="link">View Case Studies</GatsbyLink> */}
        </div>
      </div>
  )
}

class ThisProjects extends Component {
  constructor(props) {
    if (isClient) {
      const urlParams = new URLSearchParams(window.location.search);
      var regionFilter = urlParams.get('region');
    }
    super(props);
    const { regions } = props
    var regionIndex = regionFilter ? regions.findIndex(i => slugify(i.regionName) === regionFilter) : 0
    this.state = {
      activeRegion: {
        name: regions[regionIndex].regionName,
        slug: slugify(regions[regionIndex].regionName)
      },
      projectTotal: regions[regionIndex].projectTotal,
      studiesTotal: regions[regionIndex].studiesTotal,
      commoditiesTotal: regions[regionIndex].commoditiesTotal,
      mapRegions: null,
      activeMapLocation: null,
      activeCenter: null
    }
    // this.myRef = React.createRef();
  }

  loadData() {
    var regions = []
    const { data } = this.props;
    var csvJson = data.allWordpressProjectsAll.edges


    csvJson && csvJson.map(uIregion => {
      var regionIndex = regions.findIndex(i => i['region'].trim() === uIregion.node.region.trim() )
      if ( regionIndex === -1 ) {
        //Add New
        return regions.push({region: uIregion.node.region, locations: [{ location: uIregion.node.location, lat: uIregion.node.lat , lng: uIregion.node.lng, projects: [{ title: uIregion.node.project, commodity:uIregion.node.commodity }] }] })
      } else {
        //Extend region
        var locationsIndex = regions[regionIndex].locations.findIndex(i => i['location'].trim() === uIregion.node.location.trim() )
        if ( locationsIndex === -1 ) {
          //Add new location
          return regions[regionIndex].locations.push({location: uIregion.node.location, lat: uIregion.node.lat, lng: uIregion.node.lng, projects: [{ title: uIregion.node.project, commodity:uIregion.node.commodity }] })
        }else{
          //Extend locations
          return regions[regionIndex].locations[locationsIndex].projects.push({ title: uIregion.node.project, commodity:uIregion.node.commodity, start: uIregion.node.start , finish: uIregion.node.finish })
        }
      }
    })

    this.setState({
      mapRegions: regions,
    })
  }
  // onEnd() {
  //   console.log(this.refs['projectTotal']);
  // }
  componentDidMount() {
    this.loadData();
  }

  changeRegion(event, region) {
    event.preventDefault();
    const { regionName, projectTotal, studiesTotal, commoditiesTotal} = region
    this.setState({
      activeRegion: {name:regionName, slug: slugify(regionName)},
      projectTotal: projectTotal,
      studiesTotal: studiesTotal,
      commoditiesTotal: commoditiesTotal,
      activeMapLocation: null,
      activeCenter: null
    })
  }

  toggleMapLocation = (event, newIndex, centerLat, centerLng) => {
    event.preventDefault();
    if (this.state.activeMapLocation === newIndex) return this.setState({ activeMapLocation: null, activeCenter: null });
    this.setState({
      activeMapLocation: newIndex,
      activeCenter: {
        lat: parseFloat(centerLat),
        lng: parseFloat(parseInt(centerLng) + 10)}
      })
  }

  render () {
    const { mapRegions, activeRegion, activeMapLocation, activeCenter, projectTotal, studiesTotal, commoditiesTotal } = this.state
    const { regions, location } = this.props
    const activeRegionIndex = mapRegions && mapRegions.findIndex(i => i['region'] === activeRegion.name)

    var minLat = 0
    var minLng = 0
    var maxLat = 0
    var maxLng = 0

    typeof activeRegionIndex == 'number' && mapRegions[activeRegionIndex] && mapRegions[activeRegionIndex].locations.map((location, index) => {
      minLat = location.lat && Math.abs(parseInt(location.lat)) < Math.abs(parseInt(minLat)) || minLat === 0 ? parseInt(location.lat) : minLat
      maxLat = location.lat && Math.abs(parseInt(location.lat)) > Math.abs(parseInt(maxLat)) || maxLat === 0 ? parseInt(location.lat) : maxLat
      minLng = location.lng && Math.abs(parseInt(location.lng)) < Math.abs(parseInt(minLng)) || minLng === 0 ? parseInt(location.lng) : minLng
      maxLng = location.lng && Math.abs(parseInt(location.lng)) > Math.abs(parseInt(maxLng)) || maxLng === 0 ? parseInt(location.lng) : maxLng
    })

    var centerLat = ((parseInt(maxLat) - parseInt(minLat)) / 2 ) + parseInt(minLat)
    var centerLng = ((parseInt(maxLng) - parseInt(minLng)) / 2 ) + parseInt(minLng) + 10

    var center = activeCenter ? activeCenter : {lat: parseFloat(centerLat),lng: parseFloat(centerLng)}

    const createMapOptions = (maps) => {
      return {
        scrollwheel: false,
        disableDefaultUI: false,
        mapTypeId: maps.MapTypeId.ROADMAP,
        styles: [
          {
              "featureType": "all",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "saturation": 36
                  },
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 40
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.text.stroke",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 16
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 20
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 17
                  },
                  {
                      "weight": 1.2
                  },
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "labels",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 20
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#7d8f8c"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "labels",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "landscape.man_made",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#7d8f8c"
                  }
              ]
          },
          {
              "featureType": "landscape.natural.landcover",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#7d8f8c"
                  }
              ]
          },
          {
              "featureType": "landscape.natural.terrain",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "saturation": "0"
                  },
                  {
                      "visibility": "off"
                  },
                  {
                      "color": "#7d8f8c"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 21
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "labels",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 17
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 29
                  },
                  {
                      "weight": 0.2
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 18
                  }
              ]
          },
          {
              "featureType": "road.local",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 16
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 19
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 17
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#1a383b"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "labels",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          }
      ]
      };
    };
    if (!mapRegions) {
      return (
        <div className="loading"/>
      )
    }

    if (mapRegions) {

      return (
        <>
          <div className="wrapper">
            <div className="region-nav">
              {regions && regions.map((region, index) =>
                // <GatsbyLink to={`${location.pathname}?region=${slugify(region.regionName)}`} key={index} className={`${activeRegion.slug === slugify(region.regionName) ? 'active' : '' }`} onClick={() => this.changeRegion(region)}>{region.regionName}</GatsbyLink>
                <a className={region.regionName == this.state.activeRegion.name ? `active` : ''} key={index} onClick={(event) => this.changeRegion(event, region)}>{region.regionName}</a>
              )}
            </div>
            <div className="region-details">
              <div>
                <span className="value">{projectTotal > 0 && <CustomCountUp value={projectTotal} duration={5} delay={0} />}<span className="counter-char">+</span></span>
                <span className="label">Projects</span>
              </div>
              <div>
                <span className="value"><span className="counter-char"></span>{studiesTotal > 0 && <CustomCountUp value={studiesTotal} duration={5} delay={0} />}</span>
                <span className="label">Studies</span>
              </div>
              <div>
                <span className="value">{commoditiesTotal > 0 && <CustomCountUp value={commoditiesTotal} duration={5} delay={0} />}</span>
                <span className="label">Commodities</span>
              </div>
            </div>
          </div>
          { isClient && (
            <div className="google-map" id="map">
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyA1QvEsVd7FHAgMCmmvMmcnFRShCN-HeGY' }}
                options={createMapOptions}
                center={center}
                zoom={1}
              >
              {mapRegions[activeRegionIndex] && mapRegions[activeRegionIndex].locations.map((location, index) => {
                  if (location.lat && location.lng) {
                    return (
                      <MapLocation
                        lat={parseFloat(location.lat)}
                        lng={parseFloat(location.lng)}
                        key={index}
                        index={index}
                        location={location}
                        toggleMapLocation={this.toggleMapLocation}
                        active={activeMapLocation}
                      />
                    )
                  }else{
                    return null
                  }
                }
              )}
              </GoogleMapReact>
            </div>
        )}
        </>
      )
    }
  }
}


export const Projects = (props) => {
  return (
    <section className="ProjectsSection">
      <StaticQuery
        query={graphql`
          query {
            allWordpressProjectsAll {
              edges {
                node {
                  id
                  wordpress_id
                  type
                  throughput
                  project
                  lng
                  lat
                  region
                  location
                }
              }
            }
          }
        `}
        render={data => <ThisProjects {...props} data={data} />}
      />
    </section>
  );
}
