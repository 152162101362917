import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Text = (props) => {
  const { field, value, handleChange } = props
  const {type, name, placeholder, required, className } = field
  return (
    <div className={`${className}${value[slugify(name)] ? ' active' : ''}`}>
      <input id={slugify(name)} name={slugify(name)} type="text" required={required ? true : false} onChange={handleChange} value={value[slugify(name)] ? value[slugify(name)] : ''} />
      <label htmlFor={slugify(name)}>{placeholder}</label>
    </div>
  )
}
